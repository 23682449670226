@use './variables' as *;

.enterprises {
  &__list {
    display: flex;
    flex-wrap: wrap;
    padding: 0 48px;
    justify-content: space-between;
  }
}

.card__enterprise {
  flex: 0 0 47%;
  height: 645px;
  border-radius: 4px;
  margin-bottom: 48px;
  overflow: hidden;
  position: relative;
  z-index: 1;
  &--image {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &_content {
    position: relative;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
    height: 100%;
    padding: 32px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    .city {
      font-weight: 700;
      font-size: 22px;
      line-height: 33px;
      text-transform: capitalize;
      margin-bottom: 4px;
      color: $color_white;
    }
    .neighborhood {
      font-weight: normal;
      font-size: 40px;
      line-height: 32px;
      text-transform: capitalize;
      color: $color_white;
      margin-bottom: 28px;
    }
    .name {
      font-weight: 600;
      font-size: 22px;
      line-height: 14px;
      text-transform: uppercase;
      margin-bottom: 28px;
      color: $color_white;
    }
    .group {
      display: flex;
    }
    .dorms {
      margin-right: 8px;
    }
    .dorms,
    .phase {
      display: flex;
      flex-direction: row;
      padding: 8px 16px;
      border-radius: 4px;
      font-weight: 600;
      font-size: 22px;
      line-height: 33px;
      text-transform: capitalize;
      color: $color_white;
      @each $name, $region in $regions {
        .#{$name} & {
          background: $region;
        }
      }
    }
    .phase {
      background-color: #ff7276 !important;
    }
    .price {
      display: flex;
      flex-direction: column;
      margin-top: 10px;
      &-first-line {
        font-weight: 500;
        font-size: 24px;
        line-height: 36px;
        color: $color_white;
      }
      &-second-line {
        font-weight: 700;
        font-size: 32px;
        line-height: 36px;

        @each $name, $region in $regions {
          .#{$name} & {
            color: $region;
          }
        }
      }
    }
  }
}

.single {
  padding: 68px;
  .city {
    font-weight: normal;
    font-size: 40px;
    line-height: 32px;
    text-transform: capitalize;
    display: flex;
    color: $color_black;
    img {
      margin-right: 32px;
    }
  }
  .name {
    font-weight: 600;
    font-size: 54px;
    line-height: 81px;
    letter-spacing: -0.02em;
    color: $color_black;
    margin-top: 32px;
  }
  .street {
    display: flex;
    margin: 12px 0 24px;
    &--text {
      font-family: Poppins;
      font-style: normal;
      font-size: 24px;
      line-height: 36px;
      letter-spacing: -0.04em;

      color: $color_black;
    }
    &--icon {
      margin-right: 32px;
    }
  }
}
.details {
  display: flex;
  margin-top: 45px;

  .dorms,
  .phase {
    text-align: center;
    padding: 8px 16px;
    border-radius: 4px;
    font-weight: 600;
    font-size: 22px;
    line-height: 33px;
    text-transform: capitalize;
    margin: 28px 36px;
    color: $color_white;
    @each $name, $region in $regions {
      .#{$name} & {
        background: $region;
      }
    }
  }
  .phase {
    background-color: #ff7276 !important;
  }
}

.section {
  margin: 32px 0;
  &--title {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    text-transform: uppercase;
    color: $color_primary;
    display: flex;
    img {
      margin-right: 16px;
    }
  }
  &--subtitle {
    font-weight: bold;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -0.04em;
    color: $color_black;
    margin-bottom: 1rem;
  }
  &.photos {
    .photo {
      width: 100%;
    }
  }
}
.description {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1rem 2rem;

  background: $color_white;
  border-radius: 4px;

  font-weight: bold;
  font-size: 16px;
  line-height: 20px;

  text-align: center;

  color: $color_black;

  transform: translateY(-50px);
  position: absolute;
  bottom: 40px;
  left: 50%;
  width: 90%;
  margin-left: -45%;
}
