@use './variables' as *;

.button {
  display: flex;
  flex-direction: column;
  padding: 40px 72px;
  background: $color_primary;
  border-radius: 16px;
  border: none;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 60px;

  align-items: center;
  text-align: center;
  text-transform: uppercase;

  color: $color_white;

  margin: 54px 0;
  &--block {
    width: 100%;
    margin-top: 0;
  }
  &--whatsapp {
    background: #22bf4a;
    margin-bottom: 25px;
    margin-top: 54px;
    flex-direction: row;
    img {
      margin-right: 32px;
    }
  }
  &:active,
  &:hover {
    opacity: 0.5;
  }
}
