@use './variables' as *;
* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
  text-decoration: none;
}
*::placeholder {
  color: $color_primary;
}

body {
  font-family: $font_primary;
  background: $color_secondary;
  -webkit-font-smoothing: antialiased;
  color: $color_black;
}

sup {
  font-size: 26px;
  position: relative;
  top: -15px;
}
/*
  Theme: myTheme1
*/
.simple-keyboard.myTheme1 {
  background-color: #24292e;
  border-radius: 0;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  position: fixed;
  bottom: 0;
}

.simple-keyboard.myTheme1 .hg-button {
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  font-size: 48px;
}

.simple-keyboard.myTheme1 .hg-button:active {
  background: #1c4995;
  color: white;
}

#root .simple-keyboard.myTheme1 + .simple-keyboard-preview {
  background: #1c4995;
}
