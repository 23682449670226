@use './variables' as *;

.form {
  &__header {
    font-weight: bold;
    font-size: 54px;
    line-height: 81px;
    display: flex;
    margin-top: 36px;
    span {
      font-weight: 600;
      font-size: 32px;
      line-height: 48px;
      display: flex;
      align-items: center;
      text-align: center;
      justify-content: center;
      color: #ffffff;
      width: 75px;
      height: 75px;
      background: #ff1617;
      border-radius: 50%;
      flex: 0 0 auto;
      margin-right: 50px;
    }
  }
  &-group {
    padding: 0 68px;
    margin-top: 78px;
  }
  &-select,
  &-input {
    width: 100%;
    border: none;
    border-bottom: 5px solid $color_black;
    background-color: transparent;
    padding: 12px 0;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: $color_primary;
    option {
      font-size: 50px;
      max-width: 100%;
    }
  }
  &-label {
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
  }
  &-error {
    font-size: 18px;
    color: $color_primary;
    padding-top: 18px;
  }
  &-radio {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px 0px;
    margin-bottom: 25px;
    background: $color_white;
    border: 1px solid #cacccd;
    box-sizing: border-box;
    border-radius: 4px;
    font-weight: 500;
    font-size: 48px;
    line-height: 72px;
    flex: 1 0 auto;
  }

  &-control-radios {
    input {
      opacity: 0;
      position: absolute;
      & + label {
        .is-checked {
          display: none;
        }
        .no-checked {
          display: block;
        }
      }
      &:checked + label {
        background-color: $color_primary;
        color: $color_white;
        .is-checked {
          display: block;
        }
        .no-checked {
          display: none;
        }
      }
    }
  }
  &-custom-radio {
    background: $color_white;
    border: 1px solid #cacccd;
    box-sizing: border-box;

    width: 58px;
    height: 58px;
    display: block;
    margin-right: 16px;
    color: $color_white;
    font-size: 108px;
    line-height: 0;
    text-align: center;
  }
  &-custom-checkbox {
    background: $color_white;
    border: 1px solid #cacccd;
    box-sizing: border-box;

    width: 90px;
    height: 58px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
    color: $color_white;
    font-size: 32px;
    line-height: 0;
    text-align: center;
  }
  &-custom-radio {
    border-radius: 10000px;
  }
  &-label-radio {
    display: flex;
    position: relative;
    font-weight: normal;
    font-size: 48px;
    line-height: 72px;
    margin-right: 32px;
    margin-top: 32px;
    &.small {
      font-weight: normal;
      font-size: 28px;
      line-height: 42px;

      color: #8e8e8e;
    }
    input {
      opacity: 0;
      position: absolute;
      &:checked {
        & + span {
          color: $color_primary;
        }
      }
    }
  }
}
.d-flex {
  display: flex;
}
