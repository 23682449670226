@use './variables' as *;

.result {
  max-width: 1080px;
  padding: 0 68px 0 60px;
  &--title {
    font-weight: 700;
    font-size: 54px;
    line-height: 81px;
    margin-bottom: 60px;
  }
  &--subtitle {
    font-weight: 600;
    font-size: 48px;
    line-height: 72px;
    letter-spacing: -0.04em;
  }
  &-potential-amount {
    font-weight: 600;
    font-size: 120px;
    line-height: 208px;
    color: $color_green;
    margin: 32px 0;
  }
  &-checks {
    display: flex;
    flex-wrap: wrap;
    p {
      font-weight: 500;
      font-size: 24px;
      line-height: 36px;
      display: flex;
      align-items: center;
      flex: 0 0 50%;
      margin: 10px 0;
      img {
        margin-right: 16px;
      }
    }
  }
  &-legal-text {
    margin: 54px 0 170px;
    p {
      font-weight: normal;
      font-size: 20px;
      line-height: 30px;
      color: #8e8e8e;
    }
  }
}
