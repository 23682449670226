// Colors
$color_white: #ffffff;
$color_black: #000000;
$color_green: #5a9f42;
$color_primary: #ff1617;
$color_secondary: #fff5f5;
$color_tertiary: #ffe9e9;

$colorNorth: #6aaae4;
$colorEast: #ff9d6c;
$colorWest: #f9cc1d;
$colorSouth: #9164cc;
$colorDowntown: #a2d45e;
$colorCountryside: #87d1e6;
$colorOutside: #79dea8;

// Regions
$regions: (
  'east': $colorEast,
  'north': $colorNorth,
  'west': $colorWest,
  'south': $colorSouth,
  'countryside': $colorCountryside,
  'outside': $colorOutside,
  'downtown': $colorDowntown,
  'all': $color_primary,
);

//Typograph
$font_primary: 'Poppins', Arial, Helvetica, sans-serif;
