@use './variables' as *;

.container {
  max-width: 1080px;
  margin: auto;
  padding: 0 68px;
}
.header {
  margin-bottom: 45px;
  margin-top: 63px;
  &.mb-0 {
    margin-bottom: 0;
  }
}
.title {
  font-weight: bold;
  font-size: 72px;
  line-height: 120%;
  max-width: 450px;
}
.subtitle {
  font-weight: 500;
  font-size: 42px;
  line-height: 120%;
  text-align: center;
  span {
    color: $color_primary;
  }
}
.align-center {
  align-items: center;
}
.justify-between {
  justify-content: space-between;
}
.content {
  &__top {
    position: relative;
    height: 655px;
    .content--image {
      position: absolute;
      max-width: 100%;
      right: 0;
      top: -120px;
    }
  }
  &__bottom {
    background-color: $color_tertiary;
    padding: 54px 0;
  }
  &__info {
    padding-top: 54px;
    &--display {
      display: flex;
      > div {
        flex: 0 0 49%;
      }
    }
  }
}
.card {
  display: flex;
  margin-bottom: 32px;
  p {
    font-weight: 500;
    font-size: 30px;
    line-height: 45px;
    span {
      font-weight: 500;
      font-size: 80px;
      line-height: 1;
      color: $color_primary;
      &.result-subsidy-total-amount,
      &.result-parcel-amount {
        color: $color_green;
        font-size: 60px;
      }
    }
  }
  img {
    margin-right: 28px;
  }
}

.pagination {
  font-weight: 600;
  font-size: 32px;
  line-height: 48px;
  display: flex;
  align-items: center;
  text-align: center;
}
.header--display {
  display: flex;
  justify-content: space-between;
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
  }
}

.infobox {
  position: relative;
  display: flex;
  height: 744px;
  padding: 0 68px;
  align-items: baseline;
  flex: 1 0 auto;
  &.auto {
    height: auto;
  }
  &--image {
    position: absolute;
    right: 68px;
    bottom: 0;
  }
  &--content {
    align-self: flex-end;
    display: flex;
    max-width: 60%;
    transform: translateY(-340px);
    img {
      margin-right: 32px;
    }
    p {
      font-weight: normal;
      font-size: 30px;
      line-height: 45px;
      letter-spacing: -0.04em;
      display: flex;
      flex-direction: column;
    }
  }
}
.main__simulator {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.button-box {
  background-color: $color_white;
  padding: 0 68px;
  display: flex;
  align-content: center;

  .button {
    display: block;
    width: 100%;
  }
}
.card {
  &__container {
    max-width: 1080px;
    padding: 68px 68px 0;
  }
}
.card-income {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px 0;
  background: #ffe9e9;
  border-radius: 4px;
  font-weight: 700;
  font-size: 48px;
  line-height: 72px;
}
